import vSelect from "vue-select";

export default {
	props: {
		context: {
			type: Object,
			required: true
		},
	},

	components: {
		"v-select": vSelect,
	},

	mounted() {

	},

	data() {
		return {

		};
	},

	computed: {
		selectedOptions: {
			cache: false,
			get() {
				return this.$refs.select ? this.$refs.select.selectedValue : [];
			}
		}
	},

	methods: {
		onClose: function () {
			this.context.blurHandler();
		},

		onOpen() {
			// window.setTimeout(() => {
			// 	debugger;
			// }, 3000);
		},

		onOptionClick(option) {
			this.context.model.splice(this.context.model.indexOf(option.uuid), 1);
		}
	},
};
